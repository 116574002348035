/* 
This scripts requires base64-js: https://www.npmjs.com/package/base64-js 
Either use npm and a build tool or import it in the login page
*/
const base64js = require('base64-js')

/* 
Login Page must be updated: 
1. Username field must contain autocomplete="username webauthn"
2. Add this script
3. For redirect after login add sowhere in the body: <span id="loginTarget" data-url="%URL%" hidden></span>
*/
const fidoPolicyUrl = '/mga/sps/apiauthsvc/policy/fido2'

/* FUNCTIONS */
export function base64URLDecode(str) {
  if (str == null || str === '') {
    return null
  }

  str.replace(/-/g, '+').replace(/_/g, '/')

  const pad = str.length % 4
  if (pad) {
    str += new Array(5 - pad).join('=')
  }

  const bytes = base64js.toByteArray(str)
  return bytes.buffer
}

export function base64URLEncode(bytes) {
  if (bytes == null || bytes.length === 0) {
    return null
  }
  let str = base64js.fromByteArray(new Uint8Array(bytes))
  str = str.replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '')
  return str
}

export function base64URLEncodeJSON(json) {
  const str = JSON.stringify(json)
  const result = btoa(str).replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '')
  return result
}

/* LOGIC */
async function checkConditionalWebAauthn() {
  // Availability of `window.PublicKeyCredential` means WebAuthn is usable.
  if (window.PublicKeyCredential && PublicKeyCredential.isConditionalMediationAvailable) {
    // Check if conditional mediation is available.
    const isCMA = await PublicKeyCredential.isConditionalMediationAvailable()
    if (isCMA) {
      // To abort a WebAuthn call, instantiate an `AbortController`.
      const abortController = new AbortController()

      const isvaResponse = await fetch(`${fidoPolicyUrl}`, {
        method: 'GET',
        headers: new Headers({
          Accept: 'application/json'
        })
      }).then((response) => response.json())

      const publicKey = {
        rpId: isvaResponse.rpId,
        timeout: isvaResponse.timeout,
        challenge: base64URLDecode(isvaResponse.challenge),
        extensions: isvaResponse.extensions,
        userId: ''
      }

      console.log(publicKey)
      const credential = await navigator.credentials.get({
        publicKey: publicKey,
        signal: abortController.signal,
        // Specify 'conditional' to activate conditional UI
        mediation: 'conditional'
      })

      const assertionResult = {
        operation: 'verify',
        id: credential.id,
        rawId: base64URLEncode(credential.rawId),
        clientDataJSON: base64URLEncode(credential.response.clientDataJSON),
        authenticatorData: base64URLEncode(credential.response.authenticatorData),
        signature: base64URLEncode(credential.response.signature),
        userHandle: base64URLEncode(credential.response.userHandle),
        type: credential.type,
        getClientExtensionResults: base64URLEncodeJSON(credential.getClientExtensionResults())
      }

      await fetch(isvaResponse.location, {
        method: 'POST',
        body: JSON.stringify(assertionResult),
        headers: new Headers({
          Accept: 'application/json',
          'Content-Type': 'application/json'
        })
      }).then((response) => {
        if ((response.status = 204)) {
          // The API
          if (document.getElementById('loginTarget') && document.getElementById('loginTarget').dataset.url != '') {
            window.location.href = document.getElementById('loginTarget').dataset.url
          } else {
            window.location.href = '/'
          }
        } else {
          throw Error('Error during fido2 response ')
        }
      })
    }
  }
}

document.addEventListener('DOMContentLoaded', checkConditionalWebAauthn)
